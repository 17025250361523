import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
import { searchClients } from "./clients";

const initialState = {
  searchStatus: "idle",
  searchResults: [],
  messagesSearchStatus: "idle",
  conversationsSearchStatus: "idle",
  clientsSearchStatus: "idle",
  conversationsArchivedSearchStatus: "idle",
  totalPagesForMessages: null,
  totalPagesForConversations: null,
  totalPagesForArchivedConversations: null,
  totalPagesForClients: null,
  isHadAConversationStatus: "idle",
  metaKeys: [],
  statusGetMetaKeys: "idle",
};

export const generalSearch = createAsyncThunk(
  "search/generalSearch",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body.websiteID}/client/search?key=${body.key}`
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const messagesSearch = createAsyncThunk(
  "search/messagesSearch",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body.websiteID}/client/search/messages?key=${body.key}&page=${body.page}`
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const conversationsSearch = createAsyncThunk(
  "search/conversationsSearch",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body.websiteID}/client/search/conversations?key=${body.key}&page=${body.page}`
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const clientsSearch = createAsyncThunk(
  "search/clientsSearch",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body.websiteID}/client/search/users?key=${body.key}&page=${body.page}`
      );
      data = response.data.data;
      if (response.status === 200) {
        if (body?.searchClient) {
          thunkAPI.dispatch(searchClients(data));
          return;
        } else {
          return data;
        }
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const generalArchivedSearch = createAsyncThunk(
  "search/generalArchivedSearch",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body?.websiteID}/client/search/archives?key=${body?.key}`
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const messagesArchivedSearch = createAsyncThunk(
  "search/messagesArchivedSearch",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body?.websiteID}/client/search/archives/messages?key=${body?.key}&page=${body?.page}`
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const conversationsArchivedSearch = createAsyncThunk(
  "search/conversationsArchivedSearch",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body.websiteID}/client/search/archives/conversations?key=${body.key}&page=${body.page}`
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const isHadAConversation = createAsyncThunk(
  "search/isHadConversation",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body?.websiteID}/conversations/user/${body?.userID}`
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getMetaKeys = createAsyncThunk(
  "search/getMetaKeys",
  async (websiteID) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${websiteID}/conversations/meta-keys`
      );
      data = response.data?.data?.keys;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "search",
  initialState,
  extraReducers: {
    [generalSearch.pending]: (state) => {
      state.searchStatus = "loading";
    },
    [generalSearch.fulfilled]: (state, action) => {
      state.searchStatus = "success";
      state.searchResults = action.payload;
    },
    [generalSearch.rejected]: (state, action) => {
      state.searchStatus = "failed";
      state.searchResults = action.error;
    },
    [messagesSearch.pending]: (state) => {
      state.messagesSearchStatus = "loading";
    },
    [messagesSearch.fulfilled]: (state, action) => {
      state.messagesSearchStatus = "success";
      state.searchResults = {
        ...state.searchResults,
        messages: state?.searchResults?.messages?.concat(action.payload?.docs),
      };
      state.totalPagesForMessages = action.payload?.totalPages;
    },
    [messagesSearch.rejected]: (state, action) => {
      state.messagesSearchStatus = "failed";
      state.searchResults = action.error;
    },
    [conversationsSearch.pending]: (state) => {
      state.conversationsSearchStatus = "loading";
    },
    [conversationsSearch.fulfilled]: (state, action) => {
      state.conversationsSearchStatus = "success";
      state.searchResults = {
        ...state.searchResults,
        conversations: state?.searchResults?.conversations?.concat(
          action.payload?.docs
        ),
      };
      state.totalPagesForConversations = action.payload?.totalPages;
    },
    [conversationsSearch.rejected]: (state, action) => {
      state.conversationsSearchStatus = "failed";
      state.searchResults = action.error;
    },
    [clientsSearch.pending]: (state) => {
      state.clientsSearchStatus = "loading";
    },
    [clientsSearch.fulfilled]: (state, action) => {
      state.clientsSearchStatus = "success";
      state.searchResults = {
        ...state.searchResults,
        users: state?.searchResults?.users?.concat(action.payload?.docs),
      };
      state.totalPagesForClients = action.payload?.totalPages;
    },
    [clientsSearch.rejected]: (state, action) => {
      state.clientsSearchStatus = "failed";
      state.searchResults = action.error;
    },
    [generalArchivedSearch.pending]: (state) => {
      state.searchArchivedStatus = "loading";
    },
    [generalArchivedSearch.fulfilled]: (state, action) => {
      state.searchArchivedStatus = "success";
      state.searchArchivedResults = action.payload;
    },
    [generalArchivedSearch.rejected]: (state) => {
      state.searchArchivedStatus = "failed";
    },
    [messagesArchivedSearch.pending]: (state) => {
      state.messagesArchivedSearchStatus = "loading";
    },
    [messagesArchivedSearch.fulfilled]: (state, action) => {
      state.messagesArchivedSearchStatus = "success";
      state.searchArchivedResults = {
        ...state.searchArchivedResults,
        messages: state?.searchArchivedResults?.messages?.concat(
          action.payload?.docs
        ),
      };
      state.totalPagesForArchivedMessages = action.payload?.totalPages;
    },
    [messagesArchivedSearch.rejected]: (state) => {
      state.messagesArchivedSearchStatus = "failed";
    },
    [conversationsArchivedSearch.pending]: (state) => {
      state.conversationsArchivedSearchStatus = "loading";
    },
    [conversationsArchivedSearch.fulfilled]: (state, action) => {
      state.conversationsArchivedSearchStatus = "success";
      state.searchArchivedResults = {
        ...state.searchArchivedResults,
        conversations: state?.searchArchivedResults?.conversations?.concat(
          action.payload?.docs
        ),
      };
      state.totalPagesForArchivedConversations = action.payload?.totalPages;
    },
    [conversationsArchivedSearch.rejected]: (state, action) => {
      state.conversationsArchivedSearchStatus = "failed";
    },
    [isHadAConversation.pending]: (state, action) => {
      state.isHadAConversationStatus = action.meta.arg.userID;
    },
    [isHadAConversation.fulfilled]: (state) => {
      state.isHadAConversationStatus = "success";
    },
    [isHadAConversation.rejected]: (state) => {
      state.isHadAConversationStatus = "failed";
    },
    [getMetaKeys.pending]: (state, action) => {
      state.metaKeys = action.payload;
      state.statusGetMetaKeys = "loading";
    },
    [getMetaKeys.fulfilled]: (state, action) => {
      state.statusGetMetaKeys = "success";
      state.metaKeys = action.payload;
    },
    [getMetaKeys.rejected]: (state) => {
      state.statusGetMetaKeys = "failed";
    },
  },
});

export const reducer = slice.reducer;
export const {} = slice.actions;
export default slice;
